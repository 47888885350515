import React, {useEffect, useState} from 'react'
import { Tag, Modal } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styled from 'styled-components';
import './card.css';

function CardElement(props) {
  
  useEffect(() => {
    AOS.init();    
  }, []);

  const [Name, setName] = useState(props.portfolio.name);
  const [Thumbnail, setThumbnail] = useState(process.env.PUBLIC_URL + props.portfolio.thumbnail);
  const [Project, setProject] = useState(props.portfolio.project);
  const [Tags, setTags] = useState(props.portfolio.tags);  

  const renderTags = Tags.map((tag, index) => {
    return (
      <Tag className="tag" color='blue' onClick={()=>{props.handleChange(tag, true)}}>
        # {tag}
      </Tag>
    )
  });

  return (
    <div className="container" data-aos="fade-up" data-aos-duration="1000">
      <div className="post">
        <div className="header_post" onClick={()=>{props.showModal(props.portfolio)}}>
            <img src={Thumbnail} alt={Name}/>
            {/* <img src={`https://picsum.photos/512/512?random=${props.portfolio.id}`} alt={Name}/> */}
        </div>
        <div className="body_post">
            <div className="post_content">              
                <div className='post_name' onClick={()=>{props.showModal(props.portfolio)}}>
                <h1>{Name}</h1>
                <p>{Project}</p>
                </div>
                <div className="container_infos">                    
                    <div className="container_tags">
                        <span>Tags</span>
                        <div className="tags">
                                {renderTags}                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CardElement