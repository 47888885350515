import { useState, useEffect } from 'react'
import { ArrowUpOutlined } from "@ant-design/icons";
import "./TopButton.css";

export default function TopButton() {

const [ScrollY, setScrollY] = useState(0);
const [BtnStatus, setBtnStatus] = useState(false); // 버튼 상태

useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow)
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow)
    }
  })


const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if(ScrollY > 100) {
      // 100 이상이면 버튼이 보이게
      setBtnStatus(true);
    } else {
      // 100 이하면 버튼이 사라지게
      setBtnStatus(false);
    }
  }

  const handleScroll = () => {
    if (!window.scrollY) return

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    setScrollY(0);  // ScrollY 의 값을 초기화
    setBtnStatus(false); // BtnStatus의 값을 false로 바꿈 => 버튼 숨김
  }

  return (
    <div style={{position: 'sticky', bottom: '110px', float: 'right'}}>
      {/* <button className={BtnStatus ? "topBtn active" : "topBtn"} style={{fontSize: '3.8rem', color: '#ffc949', borderRadius: '50%',boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.5)"}} onClick={handleScroll}> */}
      <button className={BtnStatus ? "topBtn active" : "topBtn"} onClick={handleScroll}>
        <ArrowUpOutlined style={{fontSize: '2rem'}} />
      </button>
    </div>
  )
}