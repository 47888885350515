import React, {Suspense} from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./Components/Views/LandingPage/LandingPage";
import About from "./Components/Views/About/About";
import Works from "./Components/Views/Works/Works"
import Contact from "./Components/Views/Contact/Contact";
import NavBar from "./Components/Views/NavBar/NavBar";
import Footer from "./Components/Views/Footer/Footer";
import NotFoundPage from "./Components/Views//Status/NotFoundPage";
import styled from "styled-components";

function App() {
  return (
    <div>
      <Suspense fallback={<div>Loading..</div>}>
        <NavBar/>
        <Container>
          <Routes>          
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/works" element={<Works/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="*" element={<NotFoundPage/>}/>
          </Routes>
        </Container>
        <Footer/>
      </Suspense>
    </div>
  );
}

const Container = styled.div`
  padding-Top: 100px;
  min-Height: calc(100vh - 100px);  
  width: 100%;
  margin: auto;
  text-align: center;
  `;
export default App;
