import React, {useEffect, useState} from 'react'
import { Typography, Modal, Divider } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styled from 'styled-components';
import { Button, Form, Input } from 'antd';
import Axios from 'axios';

const { Text } = Typography;
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',    
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

function Contact() {

  const [form] = Form.useForm();
  useEffect(() => {
    AOS.init();
  }, [])
    
    const mapUrl = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12623.690194631585!2d126.6947262!3d37.7214958!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd534d5ea8def78cb!2z7Iuc64SI7KeA7JuA!5e0!3m2!1sko!2skr!4v1673870174230!5m2!1sko!2skr";        
    const emailAction = "https://script.google.com/macros/s/AKfycbxz1kFzSc9EhReMq_cUidL9dIsXdvLYOf0Dlq_jt_ULFDDJ7bQ3ex3sy3kkuk7dWLZc/exec";
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("message", values.message);
  
        Axios.post(emailAction, formData).then((response) => {
          if (response) {
            success();            
            setLoading(false);
            form.resetFields();
          }
        }).catch((err)=> {
            warning();
            setLoading(false);
        });
    };

    const valiedatPhoneNumber = (phoneNumberInput, value) => {
      // const regPhone = /^[0-9]{10,11}$/;
      var regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  
      if (!regPhone.test(value)) {
        return Promise.reject(new Error(phoneNumberInput.message));
      } else {
        return Promise.resolve();
      }
    };

    const success = () => {
      Modal.success({
        centered: 'true',
        title: 'Success',
        content: '감사합니다. 확인 후 연락드리겠습니다.',        
      });
    };

    const warning = () => {
      Modal.warning({
        centered: 'true',
        title: 'Error',
        content: '메일 전송에 실패하였습니다.',
      });
    };

  return (
    <Container>      
      <iframe src={mapUrl} width="100%" height="400px" style={{border:"none"}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            
      <ContactWrap data-aos="fade-up">
        {/* <ContentTitle>contact@synergium.co.kr</ContentTitle> */}
        <Form {...layout} style={{display:'flex', flexDirection:'column', justifyContent:'end'}} onFinish={onFinish} validateMessages={validateMessages} form={form} colon={false}>
      <Form.Item
        name="name"
        label="Company"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Company or Name' />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item 
        name="phone" 
        label="Phone"
        rules={[
          {
            // validator: valiedatPhoneNumber,
            // message: "${label} is not a valid phone number form.",
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item name="message" label="Message" 
        rules={[
          {
            required: true,
          },
        ]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item label=" ">
        <Button style={{width:'100%', height:'50px', backgroundColor:'#0F4C75', borderColor:'#0F4C75'}} type="primary" htmlType="submit" loading={loading}>
          SEND
        </Button>
      </Form.Item>
    </Form>
    </ContactWrap>
    </Container>
  )
}

const Container = styled.div`    
  margin: 0px auto;  
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const ContactWrap = styled.div`
  width: 60%;
  margin: 50px auto;
      
  @media all and (max-width: 1024px) {    
    width: 85%;
  }  
`


// const ContentTitle = styled.div`
//   font-size: 1.5rem;
//   text-transform: uppercase;
//   font-family: 'NanumSquareExtraBold';
//   border-bottom: 1px solid black;
//   text-align: end;
//   margin: 50px 0px 50px auto;
//   width: 50%;
      
//   @media all and (max-width: 1024px) {    
//     width: 50%;
//     font-size: 1.2rem;
//     padding-bottom: 5px;
//     margin: 40px 0px 40px auto;
//   }
// `


export default Contact