import React, {useEffect, useState} from 'react'
import {Menu, Drawer} from 'antd';
import { Link } from 'react-router-dom';
import "./Navbar.css";
import styled from 'styled-components';

function RightMenu(props) {
    const [selectedKey, setSelectedKey] = useState([]);
  
    useEffect(() => {
      setSelectedKey('home');
      props.menuRef.current = () => setSelectedKey('home');
    }, []);
    
  const items = [
      {
        type: "menuGroup",
        label: <Link to="/">Home</Link>,
        key: "home",          
      },
      {
        type: "menuGroup",
        label: <Link to="/about">About</Link>,
        key: "about",          
      },
      {
        type: "menuGroup",
        label: <Link to="/works">Works</Link>,
        key: "works",
      },
      {
          type: "menuGroup",
          label: <Link to="/contact">Contact</Link>,
          key: "contact",
      },
    ];

  const handleMenuClick = (e) => {
  setSelectedKey([e.key]);
  }

      
  return (
    <React.Fragment>
      <MenuContainer>
      <Menu
        items={items}
        mode="horizontal"
        onClick={handleMenuClick}
        selectedKeys={selectedKey}        
        style={{
          height: "40px",
          margin: "15px auto",
          float: "right",        
          backgroundColor: "rgba(0,0,0,0)",
          border: "none"
        }}
      />
    </MenuContainer>    
    
  </React.Fragment>
  )
}


const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
`;


export default RightMenu