import React, {useState, useEffect} from 'react'
import CardElement from './Sections/CardElement'
import { Tag, Row, Col, Divider, Input } from "antd";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import './Sections/Works.css';
import Axios from 'axios';
import TopButton from './Sections/TopButton';
import DetailModal from './Sections/DetailModal';
import MediaQuery from "react-responsive";

const { Search } = Input;
const { CheckableTag } = Tag;

let allTags = [];
let categories = [];
let subCategories = [];

function Works() {

  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [Portfolios, setPortfolios] = useState([]);
  const [Filtered, setFiltered] = useState([]);
  const [IsAll, setIsAll] = useState(true);
  const [SelectedPortfolio, setSelectedPortfolio] = useState();

  useEffect(() => {
    loadPortfolios();
  }, []);
  
  useEffect(() => {
    
  }, [Filtered, selectedTags])
  
  const showModal = (portfolios) => {    
    setSelectedPortfolio(portfolios);
    setIsModalOpen(true);
  };
  const handleOk = () => {    
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setSelectedPortfolio(null);
    setIsModalOpen(false);
  };
  
  const onSearch = (value) => {
    const userInput = value.toLowerCase().replace(/ /g, '');
    let searched = Portfolios.filter((portfolio)=> portfolio.keyword.includes(userInput));
    setFiltered(searched);

  };
  
  const loadPortfolios = () => {
    Axios.get("/data/portfolio.json").then((res)=> {
      categories = res.data.tags.categories;
      subCategories = res.data.tags.subCategories;

      const portfolios = res.data.portfolios;      
      
      portfolios.map((portfolio, index)=>{
        // portfolio.keyword = 
        portfolio.keyword = (portfolio.keyword + portfolio.name + portfolio.project + portfolio.client + portfolio.description + portfolio.tags).toLowerCase().replace(/ /g, '');
      })

      setPortfolios(portfolios);
      setFiltered(portfolios);

      allTags = [...categories, ...subCategories];

      // console.log(allTags);
      // console.log(portfolios);
    });
  }
  
  const handleChange = (tag, checked) => {    
    const isMobile = window.matchMedia("only screen and (max-width: 1024px)").matches;
    if (isMobile) {
        // mobile only code
        return;
    }

    if(tag !== 'all')
    {
      let nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
      // console.log('checked : ', nextSelectedTags);

      if(categories.includes(tag) && checked)
      {
          nextSelectedTags = nextSelectedTags.filter((t)=> !categories.includes(t));
          nextSelectedTags = [...nextSelectedTags, tag];       
      }

      setSelectedTags(nextSelectedTags);

      if(nextSelectedTags.length > 0) // 하나이상 선택되었을때
      {
        // if(JSON.stringify(nextSelectedTags.sort()) == JSON.stringify(allTags.sort())) // 전부 선택되었을때
        /*if(nextSelectedTags.length == allTags.length) // 전부 선택되었을때
        {        
          handleChange('all', true);
        } else*/
        {
          setIsAll(false);

          let filtered = Portfolios.filter((portfolio)=> nextSelectedTags.every(t => portfolio.tags.includes(t)));
          setFiltered(filtered);
          
        }
      } else // 아무것도 선택이 안되었을때 all로 변경
      {
        handleChange('all', true);
      }
    } else
    {
      setIsAll(!IsAll);      
      setSelectedTags([]);
      if(checked)
      {
        setFiltered(Portfolios);
      } else
      {
        if(selectedTags.length == 0) setIsAll(true);
      }
    }
  };

  const renderPortfolios = Filtered.map((portfolio, index) => {
    return (      
        <Col          
          sm={24}
          md={12}          
          xl={8}
          xxl={6}
          key={portfolio.id}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CardElement portfolio={portfolio} key={index} showModal={showModal} handleChange={handleChange}/>
        </Col>
    );
  });



  return (    
    <Container>      
      <CategoryContainer>
        <CategoryRow>
          <CategoryTitle>카테고리</CategoryTitle>
          <CategoryBody>
            <CheckableTag className='allTag' key={'all'} checked={IsAll} onChange={(checked) => handleChange('all', checked)}>ALL</CheckableTag>                          
                  {categories.map((tag) => (                    
                    <CheckableTag
                      key={tag}
                      className='categoryTag'
                      checked={selectedTags.includes(tag)}
                      onChange={(checked) => handleChange(tag, checked)}
                    >{tag}</CheckableTag>                    
                  ))}
          </CategoryBody>
        </CategoryRow>
        <MediaQuery maxWidth={1024}>
          <CategoryRow>
            <CategoryTitle style={{paddingTop: '10px'}}>검색</CategoryTitle>
            <CategoryBody>
              <Divider style={{margin: '5px auto', borderColor:'rgb(180, 180, 180)'}} /> 
              <Search allowClear placeholder="" onSearch={onSearch} />
            </CategoryBody>
          </CategoryRow>
        </MediaQuery>
        <MediaQuery minWidth={1025}>
        <CategoryRow>
          <CategoryTitle style={{paddingTop: '10px'}}>태그</CategoryTitle>
          <CategoryBody>
            <Divider style={{margin: '5px auto', borderColor:'rgb(180, 180, 180)'}} /> 
              <div style={{textAlign:'justify', lineHeight:'1.8rem'}}>
                {subCategories.map((tag) => (
                  <CheckableTag
                    key={tag}
                    className='myTag'
                    checked={selectedTags.includes(tag)}
                    onChange={(checked) => handleChange(tag, checked)}                    
                  >
                    # {tag}
                  </CheckableTag>
                ))}
              </div>
          </CategoryBody>
        </CategoryRow>
        </MediaQuery>
      </CategoryContainer>

        <GridWrapper>
          <Row gutter={[48, 48]} justify="start">{Filtered && renderPortfolios}</Row>    
        </GridWrapper>      
      {SelectedPortfolio != null && <DetailModal isModalOpen={IsModalOpen} handleCancel={handleCancel} portfolio={SelectedPortfolio}/>}
      <TopButton/>
      </Container>
  )
}

const Container = styled.div`    
  background-color: white;
  margin: auto;
  width: 80%;
`

const GridWrapper = styled.div`
  margin: 50px auto; 
`

const CategoryContainer = styled.div`
  margin-top: 100px;
  padding: 20px;
  border: 1px solid;  
  /* flex-direction: column; */

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  @media all and (max-width: 750px) {    
    margin-top: 60px;
    border: none;
    padding: 0px;    
  }
`

const CategoryRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px auto;
`

const CategoryTitle = styled.div`
  width:100px;
  text-Align: right;
  font-Weight:bold;
  
  @media all and (max-width: 750px) {    
    display: none;
  }
`

const CategoryBody = styled.div`
  width: 100%;
  padding-Left: 30px;
  text-Align: justify;
  @media all and (max-width: 750px) {    
    padding-Left: 0px;
    margin-bottom: 5px;
  }
`

export default Works