import React from "react";
import styled from "styled-components";

function Footer() {
  return (
    <Container>
      <FooterWrapper>
        <div style={{display:"flex", flexDirection:"column"}}>
          <div style={{fontamily: 'NanumSquare', marginBottom:"8px"}}>시너지움</div>
          <div>경기 고양시 덕양구 으뜸로 130 위프라임트윈타워 A동 1208호</div>
          <div>
            {/* 010-6633-1133 <span style={{color:"rgb(180, 180, 180)"}}>|</span> */}
             contact@synergium.co.kr</div>
        </div>
        <Copyright>
      © 2022. Synergium All rights reserved.
        </Copyright>
      </FooterWrapper>
    </Container>
  );
}

const Container = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    background-color: #1B262C;
    color: white;
    
  @media all and (max-width: 750px) {    
    height: 136px;
  }
`

const FooterWrapper = styled.div`
    display: flex;
    justify-Content: space-between;
    width: 80%;    
    position: relative;    

  @media all and (max-width: 750px) {    
    width: 85%;
    flex-direction: column;
  }
`;

const Copyright = styled.div`
    color: rgb(180, 180, 180);
    position: absolute;
    right: 0px;
    bottom:0px;
    
  @media all and (max-width: 750px) {    
    position: relative;
    margin-top: 15px;
  }
`


export default Footer;
