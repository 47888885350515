import React, { useEffect } from "react";
import { Carousel, Typography } from "antd";
import mainImg from "../../../Assets/Images/main.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import MediaQuery from "react-responsive";
import styled from "styled-components";
const { Title } = Typography;

function LandingPage() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container>
      <BackgroundImg />
      <TitleText>
        <MediaQuery minWidth={750}>
          <Title
            level={2}
            data-aos="fade-up"
            data-aos-duration="3000"
            style={{ fontFamily: "NanumSquare", color: "#fff", lineHeight:"60px" }}
          >
            <div>창의적인 기술과 감각의 시너지,</div>
            <div>고객의 가능성에 시너지를 더합니다.</div>
          </Title>
        </MediaQuery>
        <MediaQuery maxWidth={750}>
          <Title
            level={3}
            data-aos="fade-up"
            data-aos-duration="3000"
            style={{ fontFamily: "NanumSquare", color: "#fff", lineHeight:"60px"  }}
          >            
            <div>창의적인 기술과 감각의 시너지,</div>
            <div>고객의 가능성에 시너지를 더합니다.</div>
          </Title>
        </MediaQuery>
      </TitleText>
    </Container>
  );
}

const Container = styled.div`
  max-height: calc(100vh - 100px);
  color: #fff;
  text-align: center;
  background: black;
  margin: 0px;
  overflow: hidden;
`;

const BackgroundImg = styled.div`
  width: 100vw;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.5) url(${mainImg}); */
  /* background: rgba(0, 0, 0, 0.5) url('https://picsum.photos/1920/1080');   */
  /* background: rgba(0, 0, 0, 0.5) url('https://source.unsplash.com/random/1920x1080/?programming'); */
  background: rgba(0, 0, 0, 0.5) url('https://source.unsplash.com/random/960x540/?company');  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
`;

const TitleText = styled.div`
  position: absolute;
  width: 100%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export default LandingPage;
