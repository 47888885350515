import React, {useRef, useState} from 'react'
import { Link, useLocation } from "react-router-dom";
import styled from 'styled-components'
import RightMenu from './Sections/RightMenu';
import logo from "../../../Assets/synergium_white.svg"
import { MenuOutlined } from '@ant-design/icons';
import {Drawer} from 'antd';
import MediaQuery from "react-responsive";

let location = null;

function NavBar() {

  location = useLocation();
  const [visible, setVisible] = useState(false);

  const menuRef = useRef();

  const onDeselect = () => {
    menuRef.current();
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const checked = (path) => {
    if(location.pathname === '/' && path === 'home')
    {
      return "ChkItem";
    }
    else
    if (location.pathname.includes(path)) {
      return "ChkItem";
    }
    return "Item";
  };


  return (    
    <Container>
      <NavWrapper>
        <MenuLogo>
          <Link to="/" onClick={onDeselect}>
          <img
            src={logo}
            style={{ width: "150px", objectFit: "cover" }}
            alt="synergium"
          />
          </Link>
        </MenuLogo>
        <MediaQuery minWidth={750}> 
          <RightMenu menuRef={menuRef}/>
        </MediaQuery>

        <MenuButton onClick={showDrawer}><MenuOutlined /></MenuButton>
        <Drawer
        width={"40%"}
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        mask={true}
        >
        <Nav onClick={onClose}>
          <Link to="/">
            <MenuLink className={checked("home")}>Home</MenuLink>
          </Link>
          <Link to="/about">
            <MenuLink className={checked("about")}>About</MenuLink>
          </Link>
          {/* <Link to="/survey"> */}
          <Link to="/works">
            <MenuLink className={checked("works")}>Works</MenuLink>
          </Link>
          <Link to="/contact">
            <MenuLink className={checked("contact")}>Contact</MenuLink>
          </Link>
        </Nav>
      </Drawer>
      </NavWrapper>
    </Container>    
  )
}

const Container = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: #1B262C;
  display: flex;  
  justify-content: center;
  margin: auto;
  padding: 0px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  height: 100px;
  color: white;
`

const NavWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;    
`;

const MenuLogo = styled.div`   
    width: 300px;
    margin: 10px 0px 0px 0px;
    align-items: center;
    display: flex; 

    @media all and (max-width: 750px) {    
      width: 100%;      
      margin: 10px auto 0px auto;
    }
`;

const MenuButton = styled.div`
  display: none;
  
  @media all and (max-width: 750px) {
    z-index: 10;
    display: flex;
    align-items: center;
    position: absolute;
    right: 40px;
    top: 40px;
    color: white;
    font-size: 2rem;
    width: 38px;
    height: 24px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Nav = styled.nav`

  @media all and (max-width: 750px) {    
    display: flex;
    width: 70%;
    flex-direction: column;
    margin-top: 50px;
    justify-content: space-between;
    height: 240px;
    align-items: flex-end;
  }
`;

const MenuLink = styled.div`

  @media all and (max-width: 750px) {

    width: 100%;
    text-align: right;    
    font-size: 1.2rem;

    &.Item {
      color: black;
    }

    &.ChkItem {
      font-weight: bold;
      color: black;
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;


export default NavBar