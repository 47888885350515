import React, {useState, useEffect } from 'react'
import { Divider, Modal, Tag, Typography } from 'antd';
import './DetailModal.css';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';

const { Text, Title } = Typography;

function DetailModal(props) {
    const [portfolio, setPortfolio] = useState(props.portfolio);

    useEffect(() => {
        
    }, [])
    
  const renderTags = portfolio.tags.map((tag, index) => {
    return (
      <Tag className="tag" key={index}>
        # {tag}
      </Tag>
    )
  });

  const renderVideos = portfolio.video.map((video, index) => {
    return(
        <PlayerWrapper>
          <ReactPlayer className="react-player" url={video} width="100%" height="100%" muted={false} playing={false} key={index}/>
        </PlayerWrapper>         
    )    
  })

  const renderImages = portfolio.image.map((image, index) => {
    return(
        <React.Fragment>
          <Image src={image} alt={portfolio.name} loading={"lazy"} key={index} />          
        </React.Fragment>
    )    
  })

  return (
    <React.Fragment>      
        <Modal className='modal-wrap' centered title={portfolio.name} open={props.isModalOpen} onCancel={props.handleCancel} footer={null}>        
          <ModalBodyContainer>
            <LeftGroup>            
              <TitleGroup>
                <Title level={3} style={{fontFamily: 'NanumSquare'}}>{portfolio.name}</Title>
                <Text type="secondary" style={{marginTop: '-10px', fontSize: '0.8rem'}}><Divider type='vertical' /> {portfolio.project}</Text>
              </TitleGroup>
              <Divider orientation="left" orientationMargin="0" style={{fontWeight:'bold', fontFamily: 'NanumSquare'}}>Date</Divider>
              <ContentBox>{portfolio.period}</ContentBox>
              <Divider orientation="left" orientationMargin="0" style={{fontWeight:'bold', fontFamily: 'NanumSquare'}}>Client</Divider>
              <ContentBox>{portfolio.client}</ContentBox>
              <Divider orientation="left" orientationMargin="0" style={{fontWeight:'bold', fontFamily: 'NanumSquare'}}>Role</Divider>
              <ContentBox>{portfolio.role}</ContentBox>
              <Divider orientation="left" orientationMargin="0" style={{fontWeight:'bold', fontFamily: 'NanumSquare'}}>Detail</Divider>
              <ContentBox>{portfolio.description}</ContentBox>
              <Divider/>
              <p>{renderTags}</p>
            </LeftGroup>
            <RightGroup>
                {renderVideos}
                {renderImages}
            </RightGroup>
          </ModalBodyContainer>
        </Modal>
    </React.Fragment>
  )
}

const ModalBodyContainer = styled.div`
  /* border: 1px solid; */
  display: flex;
  flex-flow: row nowrap;
  margin: auto;
  width : 85%;
  align-items: stretch;
  justify-content: start;

  @media all and (max-width: 1024px) {    
    width : 100%;    
    flex-direction: column;    
  }
`

const LeftGroup = styled.div`  
  padding: 10px 30px;
  width: 50%;

  @media all and (max-width: 1024px) {  
    padding: 10px;
    width: 100%;
  }
`;

const RightGroup = styled.div`
  margin: 0px 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
  @media all and (max-width: 1024px) {
    margin: 20px auto;
    width: 100%;
  }
`;

const TitleGroup = styled.div`  
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  text-overflow:ellipsis;
  white-space:nowrap;
`;

const Image = styled.img`
  padding: 5px 0px;
  width: 100%;
`;

const ContentBox = styled.div`    
  width: 100%;
  font-size: 0.9rem;
  padding: 0px 15px;
  margin-bottom: 30px;
  color: rgb(120, 120, 120);
  text-align: justify;
  white-space:pre-wrap;
  
  @media all and (max-width: 750px) {  
    padding-left: 10px;
  }
`
const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  margin: 5px 0px;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default DetailModal