import React, { useEffect } from "react";
import { Typography } from "antd";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import MediaQuery from "react-responsive";
import coreValue from '../../../Assets/Images/values.png';

const { Title } = Typography;

function About() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <Container>
      <Contents>
        <CoreValues data-aos="zoom-in"/>
        <ContentTitle data-aos="fade-up">Who We Are</ContentTitle>
        <ContentWhoWeAre data-aos="fade-up">
        <div>스스로의 정직 / 서로의 신뢰 / 과정의 재미 / 역량의 향상</div>
        <div style={{margin:"15px 0px"}}>위 가치를 중요하게 여기는 다양한 분야의 전문가가 모여 시너지움을 이루었습니다.</div>
        <div>건강한 가치들의 시너지.</div>
        <div>시너지움 입니다.</div>
        </ContentWhoWeAre>

        <ContentTitle data-aos="fade-up">What We Do</ContentTitle>
        <ContentWhatWeDo data-aos="fade-up">
        <div>시너지움은 10년 이상 다양한 분야에서 노하우를 쌓은 전문가들이 모인 Digital Experience 전문 개발기업으로 디지털 전시, 교육, 기업용 솔루션, 게임 등 다양한 분야의 소프트웨어 개발 서비스를 제공하고 있습니다.</div>
        <div>시너지움은 항상 새로운 아이디어와 기술을 도입하여 혁신적인 디지털 경험을 제공하기 위해 노력하며, 고객과의 긴밀한 협력을 통해 함께 성장하는 파트너가 되겠습니다.</div>
        </ContentWhatWeDo>

        {/* <ContentTitle data-aos="fade-right">Our Vision</ContentTitle>
        <ContentOurVision data-aos="fade-left">
          국가는 균형있는 국민경제의 성장 및 안정과 적정한 소득의 분배를
          유지하고, 시장의 지배와 경제력의 남용을 방지하며, 경제주체간의 조화를
          통한 경제의 민주화를 위하여 경제에 관한 규제와 조정을 할 수 있다.
        </ContentOurVision> */}
      </Contents>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media all and (max-width: 1024px) {
    margin: 0px 50px;
  }
`;

const CoreValues = styled.div`
  background-image: url(${coreValue});
  background-size: contain;
  width: 500px;
  height: 500px;
  margin: 30px 0px;

  @media all and (max-width: 1024px) {
    width: 400px;
    height: 400px;
  }

  @media all and (max-width: 750px) {
    width: 300px;
    height: 300px;
  }
`

const Contents = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 100px 0px;

  @media all and (max-width: 1024px) {    
    width: 80%;
  }

  @media all and (max-width: 750px) {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 80px;
  }
`;

const ContentTitle = styled.div`
  font-size: 2.5rem;
  text-transform: uppercase;
  font-family: "NanumSquareExtraBold";
  border-bottom: 1px solid black;
  text-align: start;
  padding-bottom: 10px;
  margin: 50px 0px;
  width: 100%;

  @media all and (max-width: 750px) {
    font-size: 2rem;
    padding-bottom: 5px;
    margin: 40px 0px;
  }
`;

const ContentWhoWeAre = styled.div`
  width: 100%;
  padding: 0px 10px 0px 30px;
  text-align: start;
  line-height: 2rem;

  @media all and (max-width: 750px) {
    padding: 0px 5px 0px 10px;
  }
`;

const ContentWhatWeDo = styled.div`
  width: 100%;
  padding: 0px 10px 0px 30px;
  text-align: start;
  line-height: 2rem;

  @media all and (max-width: 750px) {
    padding: 0px 5px 0px 10px;
  }
`;

const ContentOurVision = styled.div`
  width: 100%;
  padding: 0px 10px 0px 30px;
  text-align: start;
  line-height: 2rem;

  @media all and (max-width: 750px) {
    padding: 0px 5px 0px 10px;
  }
`;

export default About;
